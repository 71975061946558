<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
            <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
            <v-progress-circular
        indeterminate
        color="primary"
        ></v-progress-circular>
        </v-overlay>
        <v-card-title>Update Value Pass</v-card-title>
        <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-container class="fill-height"
        fluid>
        <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    label="Operator"
                    v-model="form[24].selected"
                    @change="execFilter('operator')"
                    :items="form[24].operator"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="form[24].rule"
                    :loading="form[24].loader"
                    hide-details="auto"
                    background-color="white"
                    :disabled="readonly"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    label="Car Park *"
                    v-model="form[0].selected"
                    :items="form[0].carpark"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="form[0].rule"
                    :loading="form[0].loader"
                    hide-details="auto"
                    background-color="white"
                    :disabled="readonly"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                <v-row style="border: 1px solid grey; margin: 1px">
                    <v-col cols="12" md="12"><span class="text-body-2">Value Pass Details</span></v-col>
                    <v-col cols="12" md="3">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-file-input outlined prepend-icon="" dense persistent-hint hint="Horizontal: 400 Vertical: 400" background-color="white" label="Image *" v-model="form[4].file" :rules="form[4].imgrule" type="file" accept="image/*" :disabled="readonly"></v-file-input>
                                <v-card class="mb-6" style="height: 200px; z-index: 1" outlined @drop.prevent="addDropFile4" @dragover.prevent>
                                    <v-overlay
                                    :absolute="true"
                                    opacity="1"
                                    color="grey"
                                    :value="form[4].image == '' || form[4].image == undefined ? true : false"
                                    >
                                    <v-row>
                                        <v-col style="text-align: center">
                                            <p>Drag & Drop or Select Image from Folder</p>
                                            <v-icon x-large>fa fa-image</v-icon>
                                        </v-col>
                                    </v-row>
                                    </v-overlay>
                                    <img :src="form[4].image" :style="{width: '200px'}" />
                                    <v-overlay
                                    :absolute="true"
                                    :value="form[4].btn_loading"
                                    >
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="9">
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="displayitems.vpid"
                                label="VP ID"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="form[1].vpname"
                                :rules="form[1].rule"
                                label="Value Pass Name *"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                :disabled="readonly"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-checkbox v-model="form[5].purchasecode" dense hide-details="auto" :disabled="readonly">
                                        <template v-slot:label class="checkbox-role"><span style="width: 100%; display: flex; white-space: nowrap;" @click="changeOrder">Require Purchase Code</span>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        dense
                                        outlined
                                        type="text"
                                        v-model="form[5].code"
                                        disabled
                                        autocomplete="off"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-checkbox v-model="form[21].displaystatus" dense style="flex: 0 0 50%; max-width: 50%;" hide-details="auto" :disabled="readonly">
                                        <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">Display On Mobile</span></template>
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <span class="text-body-2">Status</span>
                                    <v-switch
                                    color="success"
                                    class="col-1 pl-0"
                                    v-model="form[26].active"
                                    hide-details="off"
                                    :label="`${form[26].active ? 'Enabled' : 'Disabled'}`"
                                    :disabled="readonly"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="displayitems.createddate"
                                label="Created Date"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="displayitems.updateddate"
                                label="Updated Date"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                :value="displayitems.createdby"
                                label="Created By"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                v-model="displayitems.updatedby"
                                label="Updated By"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                type="text"
                                :value="displayitems.createdbyrole"
                                label="Created By Role"
                                required
                                background-color="white"
                                hide-details="auto"
                                autocomplete="off"
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <span class="text-body-2">Description *</span>
                    <text-editor v-if="componentloader" v-model="form[2].vpdesc" :value="form[2].vpdesc" :color="'white'" :style="readonly ? {'pointer-events': 'none'} : ''"></text-editor>
                    <v-input
                        error
                        readonly
                        v-if="componentloader"
                        v-model="form[2].vpdesc"
                        :rules="form[2].rule"
                        height="0"
                        class="hide-input-text"
                        hide-details="auto"
                        :disabled="readonly"
                    >
                    </v-input>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <span class="text-body-2">Terms & Conditions *</span>
                    <text-editor v-if="componentloader" v-model="form[3].tnc" :value="form[3].tnc" :color="'white'" :style="readonly ? {'pointer-events': 'none'} : ''"></text-editor>
                    <v-input
                        error
                        readonly
                        v-if="componentloader"
                        v-model="form[3].tnc"
                        :rules="form[3].rule"
                        height="0"
                        class="hide-input-text"
                        hide-details="auto"
                    >
                    </v-input>
                </v-col>
                <v-col cols="12" md="6">
                <v-row style="border: 1px solid grey; margin: 1px">
                <v-col cols="12" md="12"><span class="text-body-2">Price and Quantity</span></v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="form[11].vpcost"
                        :rules="form[11].rule"
                        label="Unit Price (RM) *"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        :disabled="readonly || this.displayitems.totalsold > 0"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="form[6].qty"
                        :rules="form[6].rule"
                        label="Total Quantity"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="form[25].addonqty"
                        :rules="form[25].rule"
                        label="Add On Quantity"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="form[7].minpurchaseqty"
                        :rules="form[7].rule"
                        label="Minimum Purchase *"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="form[8].maxpurchaseqty"
                        :rules="form[8].rule"
                        label="Maximum Purchase *"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        :disabled="readonly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="displayitems.totalsold"
                        label="Total Sold"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="displayitems.totalunsold"
                        label="Total Unsold"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                    ></v-text-field>
                </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" md="6">
                <v-row style="border: 1px solid grey; margin: 1px">
                <v-col cols="12" md="12"><span class="text-body-2">Applicable Date Time</span></v-col>
                <v-col cols="12" sm="12" md="6">
                    <vc-date-picker v-model="form[9].range.start" mode="date" locale="en-GB">
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    @click="togglePopover({ placement: 'top-start' })"
                                    :value="inputValue"
                                    @keyup="removeValStart"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    background-color="white"
                                    label="Start Selling Date *"
                                    :rules="form[9].rulestart"
                                    autocomplete="off"
                                    :disabled="readonly"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <vc-date-picker v-model="form[9].range.end" mode="date" locale="en-GB">
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    @click="togglePopover({ placement: 'top-start' })"
                                    :value="inputValue"
                                    @keyup="removeValEnd"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    background-color="white"
                                    label="Stop Selling Date *"
                                    :rules="form[9].ruleend"
                                    autocomplete="off"
                                    :disabled="readonly"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <vc-date-picker v-model="form[10].range.start" mode="date" locale="en-GB">
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    @click="togglePopover({ placement: 'top-start' })"
                                    :value="inputValue"
                                    @keyup="removeValStartUse"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    background-color="white"
                                    label="Start Use Date *"
                                    :rules="form[10].rulestart"
                                    autocomplete="off"
                                    :disabled="readonly"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <vc-date-picker v-model="form[10].range.end" mode="date" locale="en-GB">
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                    @click="togglePopover({ placement: 'top-start' })"
                                    :value="inputValue"
                                    @keyup="removeValEndUse"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    background-color="white"
                                    label="Stop Use Date *"
                                    :rules="form[10].ruleend"
                                    autocomplete="off"
                                    :disabled="readonly"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" md="12">
                <v-row style="border: 1px solid grey; margin: 1px">
                    <v-col cols="12" md="12"><span class="text-body-2">Value Pass Condition</span></v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                        dense
                        outlined
                        label="Value Pass Type *"
                        v-model="form[12].selected"
                        @change="execFilter('vptype')"
                        :items="form[12].vptype"
                        item-text="name"
                        item-value="id"
                        :rules="form[12].rule"
                        :loading="form[12].loader"
                        hide-details="auto"
                        background-color="white"
                        :disabled="readonly || this.displayitems.totalsold > 0"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                        dense
                        outlined
                        label="Utilize Type"
                        v-model="displayitems.utilizetype.selected"
                        @change="execFilter('utilizetype')"
                        :items="displayitems.utilizetype.items"
                        item-text="name"
                        item-value="id"
                        :rules="displayitems.utilizetype.rule"
                        :loading="displayitems.utilizetype.loader"
                        hide-details="auto"
                        background-color="white"
                        disabled
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            dense
                            outlined
                            type="text"
                            v-model="form[14].numofentry"
                            :rules="form[14].rule"
                            label="Number of Entry"
                            required
                            hide-details="auto"
                            background-color="white"
                            autocomplete="off"
                            :disabled="!isNumOfEntryTrue() || readonly"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            dense
                            outlined
                            type="text"
                            v-model="form[23].numofparkingdays"
                            :rules="form[23].rule"
                            label="Number of Parking Day"
                            required
                            background-color="white"
                            hide-details="auto"
                            autocomplete="off"
                            :disabled="!isNumOfParkingDaysTrue() || readonly"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <tpicker v-if="componentloader" :datetime="form[16]" @time="dateValStartTime" label="Start Hour of Entry *" :width="'98px'" :readonly="readonly"></tpicker>
                    </v-col>
                    <v-col cols="12" md="12">
                        <tpicker v-if="componentloader" :datetime="form[17]" @time="dateValEndTime" label="End Hour of Entry *" :width="'98px'" :readonly="readonly"></tpicker>
                    </v-col>
                    <v-col cols="12" md="12">
                        <tpicker v-if="componentloader" :datetime="form[18]" @time="dateValExitStartTime" label="Start Hour of Exit *" :width="'98px'" :readonly="readonly"></tpicker>
                    </v-col>
                    <v-col cols="12" md="12">
                        <tpicker v-if="componentloader" :datetime="form[19]" @time="dateValExitEndTime" label="End Hour of Exit *" :width="'98px'" :readonly="readonly"></tpicker>
                    </v-col>
                    <v-col cols="12" md="12" v-if="form[12].selected === 'duration'">
                        <v-text-field
                            dense
                            outlined
                            type="text"
                            v-model="form[15].allowedparkingdurationhours"
                            :rules="form[15].rule"
                            label="Parking Duration"
                            required
                            background-color="white"
                            hide-details="auto"
                            autocomplete="off"
                            hint="Total duration of parking start count from user entry time."
                            persistent-hint
                            :disabled="readonly"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <vc-date-picker :attributes="attributes" mode="multiple" @dayclick="onDayClick" :masks="{ input: 'DD/MM/YYYY' }">
                        <template v-slot="{ inputValue, inputEvents, togglePopover }">
                            <v-row dense>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        @click="togglePopover({ placement: 'top-start' })"
                                        :value="form[22].allowedentrydates.map(a => ' ' + `${a.id}`)"
                                        @keyup="removeValEndUse"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        background-color="white"
                                        label="Applicable Date"
                                        autocomplete="off"
                                        hint="Value Pass is applicable on these date only. Leave it blank if not fix to any date."
                                        persistent-hint
                                        :disabled="readonly"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                        </vc-date-picker>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <span class="text-body-2">Applicable Days</span>
                        <v-checkbox dense @change="selectAll" hide-details v-model="select_all" :disabled="readonly">
                            <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">Check All</span></template>
                        </v-checkbox>
                        <v-checkbox multiple dense style="flex: 0 0 50%; max-width: 50%;" v-for="(item2, index2) of form[20].alloweddays" hide-details :value="item2.id" v-model="form[20].selected" :key="index2" :disabled="readonly">
                            <template v-slot:label class="checkbox-role"><span style="width: 100%" @click="changeOrder">{{item2.name}}</span></template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                </v-col>
                <v-card-actions>
                    <v-btn v-if="!readonly" class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                    <v-btn class="mt-4" tile small color="error" type="button" :to="{ name: 'Value Pass' }"><v-icon left x-small>fa fa-ban</v-icon> Cancel</v-btn>
                </v-card-actions>
        </v-row>
        </v-container>
        </v-form>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_edit" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
    </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/AddRate';
import edit from '.././modals/EditRate';
import tpicker from "../TimePickerComponent";
import alertbox from '../modals/Alert';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Update',
    title: 'Update',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Marketing',
                disabled: true,
                href: '#',
                },

                {
                text: 'Value Pass',
                disabled: true,
                href: '#',
                },

                {
                text: 'Update',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            cutoffval: '',
            cutoffrule: [],
            graceval: '',
            gracerule: [],
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Day', value: 'day', width: '20%' },
                { text: 'Action', value: 'add', width: '10%' },
                { text: 'Rates', value: 'rates', width: '10%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,

                modal_edit: false,
            },
            form: [ 
                {carpark: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false, }, //0
                {vpname: '', rule: [] }, //1
                {vpdesc: '', rule: [] }, //2
                {tnc: '', rule: [] }, //3
                {file: '', imgrule: [], image: '', btn_loading: '', logo: '' }, //4
                {purchasecode: false, rule: [], code: '' }, //5
                {qty: null, rule: [] }, //6
                {minpurchaseqty: null, rule: [] }, //7
                {maxpurchaseqty: null, rule: [] }, //8
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //9 sell date
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //10 use date
                {vpcost: '', rule: [] }, //11
                {vptype: [{id: '', name: 'Select'}, {id: 'time', name: 'Time'}, {id: 'duration', name: 'Duration'}], rule: [], selected: '' }, //12
                {multientry: false, rule: [] }, //13
                {numofentry: '', rule: [] }, //14
                {allowedparkingdurationhours: '', rule: [] }, //15
                {entrytimestart: '', rule: [] }, //16
                {entrytimeend: '', rule: [] }, //17
                {exittimestart: '', rule: [] }, //18
                {exittimeend: '', rule: [] }, //19
                {alloweddays: [
                    {id: 'Monday', name: 'Monday'}, 
                    {id: 'Tuesday', name: 'Tuesday'}, 
                    {id: 'Wednesday', name: 'Wednesday'},
                    {id: 'Thursday', name: 'Thursday'},
                    {id: 'Friday', name: 'Friday'},
                    {id: 'Saturday', name: 'Saturday'},
                    {id: 'Sunday', name: 'Sunday'},
                    ],
                    rule: [], selected: []
                }, //20
                {displaystatus: false, rule: [] }, //21
                {allowedentrydates: [], rule: [] }, //22
                {numofparkingdays: null, rule: [] }, //23
                {operator: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false, }, //24
                {addonqty: '', rule: [] }, //25
                {active: false }, //26
            ],
            displayitems: {
                createddate: '',
                updateddate: '',
                createdby: '',
                createdbyrole: '',
                updatedby: '',
                vpid: '',
                totalsold: '',
                totalunsold: '',
                utilizetype: {
                    selected: '',
                    items: [
                        {name: 'Select', id: ''},
                        {name: 'Per Entry Per Day', id: '1'},
                        {name: 'Per Entry Multiple Days', id: '2'},
                        {name: 'Multiple Entries Per Day', id: '3'},
                        {name: 'Multiple Entries Multiple Days', id: '4'},
                    ],
                    rule: [],
                    loader: false
                }
            },
            componentloader: false,
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,
            firstloadeditems: [],
            readonly: false
        }
    },

    components: {create, edit, alertbox, 'text-editor': TextEditor, tpicker},

    async mounted(){
        //this.$vuetify.goTo(0);
        if (!this.validateAccess('valuepass_update')){
            this.readonly = true;
        }
        await this.getOperatorFull();
        await this.getCarParkFull();
        this.numofentrycheck;
        this.durationcheck;
        await this.getValuePass();
        this.selectUtilizeType();
        this.$watch(() => this.form[4].file, this.handleImageMain, {deep: true});
        this.$watch(() => this.form[13].multientry, this.numofentrycheck, {deep: true});
        this.$watch(() => this.form[12].selected, this.durationcheck, {deep: true});
        //this.$watch(() => this.form[16].entrytimestart, this.handleInputStartHour, {deep: true});
    },

    async beforeRouteLeave (to, from , next) {
        let a = JSON.stringify(this.firstloadeditems) === JSON.stringify(this.form);
        if (this.ma.modal_action_type === ''){
            if (!a){
                const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
                if (answer) {
                    next()
                } else {
                    next(false);
                }
            }

            else {
                next();
            }
        }

        else{
            next();
        }
    },

    computed: {
        dates() {
        return this.form[22].allowedentrydates.map(day => day.date);
        },
        attributes() {
        return this.dates.map(date => ({
            highlight: true,
            dates: date,
        }));
        },
    },

    methods: {
        async getValuePass(pg){
            try{
                this.overlay = true;
                let response = await this.$store.dispatch("getValuePassList", {page: 1, operatorid: null, carparkid: null, valuepassproductid: Number(this.$route.params.id)});
                let responsetnc = await this.$store.dispatch("getValuePassTNC", {valuepassproductid: Number(this.$route.params.id)});

                if (response.data.code == 'AP000'){
                    let dt = response.data.record;
                    let tnc = responsetnc.data.record[0];

                    for(var i=0; i<dt.length; i++){
                        dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                        dt[i].displaystatus = dt[i].MobileDisplayStatus == true ? 'Yes' : 'No';
                        dt[i].startendusedate = `${moment(dt[i].StartUseDate).format('DD-MM-YYYY')} - ${moment(dt[i].EndUseDate).format('DD-MM-YYYY')}`;
                        dt[i].DateCreated = moment(new Date(dt[i].DateCreated)).format('DD-MM-YYYY hh:mm:ss A');
                        //replace(/\s/g,'') remove any literal whitespace
                    }

                    console.log(tnc);

                    this.items = dt;
                    this.pages = Math.ceil(5 / 100); //to update
                    this.overlay = false;

                    let startselldate = new Date(moment(new Date(new Date(this.items[0].StartSellDate))).format('MM/DD/YYYY hh:mm:ss A'));
                    //startselldate.setHours(0,0,0,0);

                    let endselldate = new Date(moment(new Date(new Date(this.items[0].EndSellDate))).format('MM/DD/YYYY hh:mm:ss A'));
                    //endselldate.setHours(23,59,59,0);

                    let startusedate = new Date(moment(new Date(new Date(this.items[0].StartUseDate))).format('MM/DD/YYYY hh:mm:ss A'));
                    //startusedate.setHours(0,0,0,0);

                    let endusedate = new Date(moment(new Date(new Date(this.items[0].EndUseDate))).format('MM/DD/YYYY hh:mm:ss A'));
                    //endusedate.setHours(23,59,59,0);

                    this.form[0].selected = this.items[0].CarParkID;
                    console.log('images', this.items[0].Images[0]);
                    this.form[4].image = this.items[0].Images[0];
                    this.form[4].file = '';
                    this.form[1].vpname = this.items[0].ValuePassName;
                    this.form[2].vpdesc = this.items[0].Description;
                    this.form[3].tnc = tnc.TNC;
                    this.form[11].vpcost = this.items[0].CostPerValuePass;
                    this.form[5].purchasecode = this.items[0].IsPurchaseCodeApplicable === 1 ? true : false;
                    this.form[5].code = this.items[0].IsPurchaseCodeApplicable === 1 ? this.items[0].PurchaseCode : null;
                    this.form[21].displaystatus = this.items[0].MobileDisplayStatus;
                    this.form[26].active = this.items[0].Active;
                    this.form[9].range.start = startselldate;
                    this.form[9].range.end = endselldate;
                    this.form[10].range.start = startusedate;
                    this.form[10].range.end = endusedate;
                    this.form[6].qty = this.items[0].TotalQuantity;
                    this.form[7].minpurchaseqty = this.items[0].MinPurchaseQuantity;
                    this.form[8].maxpurchaseqty = this.items[0].MaxPurchaseQuantity;
                    this.form[23].numofparkingdays = this.items[0].ValuePassRules.allowed_parking_days;
                    this.form[13].multientry = this.items[0].MultipleEntriesPerValuePass;
                    this.form[14].numofentry = this.items[0].ValuePassRules.number_of_entries;
                    this.form[15].allowedparkingdurationhours = this.items[0].ValuePassRules.allowed_parking_duration_hours;
                    this.form[12].selected = this.items[0].Type;
                    this.form[16].entrytimestart = this.items[0].ValuePassRules.allowed_entry_time_start == null ? "" : moment(this.items[0].ValuePassRules.allowed_entry_time_start, 'HH:mm:ss').format("hh:mm a");
                    this.form[17].entrytimeend = this.items[0].ValuePassRules.allowed_entry_time_end == null ? "" : moment(this.items[0].ValuePassRules.allowed_entry_time_end, 'HH:mm:ss').format("hh:mm a");
                    this.form[18].exittimestart = this.items[0].ValuePassRules.allowed_exit_time_start == null ? "" : moment(this.items[0].ValuePassRules.allowed_exit_time_start, 'HH:mm:ss').format("hh:mm a");
                    this.form[19].exittimeend = this.items[0].ValuePassRules.allowed_exit_time_end == null ? "" : moment(this.items[0].ValuePassRules.allowed_exit_time_end, 'HH:mm:ss').format("hh:mm a");
                    let alloweddates = this.items[0].ValuePassRules.only_allowed_dates.map(i => ({
                        id: moment(i).format('DD/MM/YYYY'),
                        date: new Date(i)
                    }));
                    let days_selected = [
                        this.items[0].ValuePassRules.allowed_day_mon ? 'Monday' : '',
                        this.items[0].ValuePassRules.allowed_day_tue ? 'Tuesday' : '',
                        this.items[0].ValuePassRules.allowed_day_wed ? 'Wednesday' : '',
                        this.items[0].ValuePassRules.allowed_day_thur ? 'Thursday' : '',
                        this.items[0].ValuePassRules.allowed_day_fri ? 'Friday' : '',
                        this.items[0].ValuePassRules.allowed_day_sat ? 'Saturday' : '',
                        this.items[0].ValuePassRules.allowed_day_sun ? 'Sunday' : '',
                    ];

                    this.form[20].selected = days_selected.filter(i => i !== '');
                    
                    this.form[22].allowedentrydates = alloweddates;
                    this.displayitems.createddate = this.items[0].DateCreated;
                    this.displayitems.createdby = this.items[0].CreatedByAdminName || this.items[0].CreatedByOperatorName || "N/A";
                    this.displayitems.createdbyrole = this.items[0].CreatedByRole.charAt(0).toUpperCase() + this.items[0].CreatedByRole.slice(1).toLowerCase();
                    this.displayitems.updatedby = this.items[0].UpdatedByAdminName || this.items[0].UpdatedByOperatorName || "N/A";
                    this.displayitems.updateddate = this.items[0].DateUpdated == null ? "N/A" : moment(new Date(this.items[0].DateUpdated)).format('DD-MM-YYYY hh:mm:ss A');
                    this.displayitems.vpid = this.items[0].ValuePassProductID;
                    this.displayitems.totalsold = this.items[0].QuantitySold;
                    this.displayitems.totalunsold = this.items[0].SellingQuantityLeft;
                    this.firstloadeditems = JSON.parse(JSON.stringify(this.form));
                    this.componentloader = true;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.form[24].operator = [];
            this.form[24].selected = "";

            try{
                this.form[24].loader = true;
                this.form[24].disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.form[24].operator.push({name: "Select", OperatorID: ""});
                    this.form[24].itemsFull.push({name: "Select", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.form[24].operator.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.form[24].itemsFull.push(dt[i]);
                    }
                }

                this.form[24].selected = this.form[24].operator[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.form[24].loader = false;
                this.form[24].disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.form[0].carpark = [];
            this.form[0].selected = "";

            try{
                this.form[0].loader = true;
                this.form[0].disable = true;

                let self = this;

                if (this.form[24].selected){
                    let carpark = this.form[0].itemsFull.filter(item => {
                    return item.OperatorID == self.form[24].selected;
                    });

                    this.form[0].carpark.push({name: "Select", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.form[0].carpark.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.form[0].carpark.push({name: "Select", CarParkID: ""});
                        this.form[0].itemsFull.push({name: "Select", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.form[0].carpark.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.form[0].itemsFull.push(dt[i]);
                        }
                    }

                    this.form[0].selected = this.form[0].carpark[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.form[0].loader = false;
                this.form[0].disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async updateValuePass(fDays){
            this.btn_loading = true;
            this.loading = true;
            let allowedentrydates = this.form[22].allowedentrydates.map(i => moment(i.date).format('YYYY-MM-DD'));
            try{
                this.swal.scolor = 'green';
                let req = {
                    ValuePassProductID: this.items[0].ValuePassProductID,
                    CarParkID: this.form[0].selected,
                    ValuePassName: this.form[1].vpname,
                    Description: this.form[2].vpdesc,
                    TNC: this.form[3].tnc,
                    Image: this.form[4].image,
                    PurchaseCodeApplicable: this.form[5].purchasecode,
                    Quantity: this.form[6].qty,
                    MinPurchaseQuantity: this.form[7].minpurchaseqty,
                    MaxPurchaseQuantity: this.form[8].maxpurchaseqty,
                    StartSellDate: this.form[9].range.start !== '' ? moment(new Date(this.form[9].range.start).setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss') : '',
                    EndSellDate: this.form[9].range.end !== '' ? moment(new Date(this.form[9].range.end).setHours(23, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss') : '',
                    StartUseDate: this.form[10].range.start !== '' ? moment(new Date(this.form[10].range.start).setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss') : '',
                    EndUseDate: this.form[10].range.end !== '' ? moment(new Date(this.form[10].range.end).setHours(23, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss') : '',
                    ValuePassCost: this.form[11].vpcost,
                    ValuePassType: this.form[12].selected,
                    MultipleEntriesPerValuePass: this.form[13].multientry,
                    NumberOfEntries: this.form[14].numofentry,
                    NumberOfParkingDays: this.form[23].numofparkingdays,
                    AllowedParkingDurationHours: this.form[15].allowedparkingdurationhours,
                    AllowedEntryTimeStart: this.form[16].entrytimestart == "" ? "" : moment(this.form[16].entrytimestart, 'hh:mm a').format("HH:mm:ss"),
                    AllowedEntryTimeEnd: this.form[17].entrytimeend == "" ? "" : moment(this.form[17].entrytimeend, 'hh:mm a').format("HH:mm:ss"),
                    AllowedExitTimeStart: this.form[18].exittimestart == "" ? "" : moment(this.form[18].exittimestart, 'hh:mm a').format("HH:mm:ss"),
                    AllowedExitTimeEnd: this.form[19].exittimeend == "" ? "" : moment(this.form[19].exittimeend, 'hh:mm a').format("HH:mm:ss"),
                    AllowedEntryDates: allowedentrydates.join(", "),
                    AllowedDays: fDays,
                    MobileDisplayStatus: this.form[21].displaystatus,
                    AdditionalValuePassQuantity: this.form[25].addonqty,
                    Active: this.form[26].active
                };
                let response = await this.$store.dispatch("updateValuePass", req);
                //await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    if (this.form[4].file != '' && this.form[4].file != undefined)
                        await this.addValuePassImage();

                    console.log('trigger purchase code', this.form[5].code);
                    if (this.form[5].purchasecode && this.form[5].code == null){
                        console.log('trigger purchase code');
                        await this.generatePurchaseCode();
                    }
                    //this.swal.notification = true;
                    //this.swal.scolor = 'green';
                    //this.swal.message = `${ERR.HANDLE(response.data.code)}: Value Pass Created!`;
                    this.$router.push({ name: 'Value Pass', query: {updated: true, id: this.items[0].ValuePassProductID, cpid: this.form[0].selected}});
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This Value Pass is already created`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.loading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        async generatePurchaseCode(){
            try{
                let req = {
                    ValuePassProductID: this.items[0].ValuePassProductID,
                };
                let response = await this.$store.dispatch("generatePurchaseCode", req);
                //await timer.sleep(800);
                if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Purchase Code unable to be generated`;
                }
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        async addValuePassImage(){
            try{
                let req = {
                    ValuePassProductID: this.items[0].ValuePassProductID,
                    Image: this.form[4].image,
                };
                await this.deleteValuePassImage();
                let response = await this.$store.dispatch("addValuePassImage", req);
                //await timer.sleep(800);
                if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Image unable to be added`;
                }
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        async deleteValuePassImage(){
            try{
                let req = {
                    ValuePassProductID: this.items[0].ValuePassProductID,
                    Image: this.items[0].Images[0],
                };
                await this.$store.dispatch("deleteValuePassImage", req);
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.loading = false;
                this.btn_loading = false;
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        onClosed(value){
            console.log(value);
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_edit = value;
                this.ma.alertmodal = value;
            }
        },

        filterDays(itm) {
            let a = this.form[20].selected.some(i => i == itm.id);
            let days = {[itm.id]: a};
            
            return days;
        },

        onConfirm(value){
            console.log(value);
            if (value == true){
                this.ma.confirmCreate = true;
                this.ma.alertmodal = false;

                //format days to to satisfy api format
                let alloweddays = this.form[20].alloweddays.map(this.filterDays);
                let formatteddays = {};
                for (let [index, item] of alloweddays.entries()){
                    let valueofitem = Object.values(item)[0];
                    formatteddays[Object.keys(item)] = valueofitem;
                }
                //end of format days

                this.updateValuePass(formatteddays);
            }
        },

        onRuleChange() {
            this.form[1].rule = []; this.form[2].rule = []; this.form[3].rule = []; this.form[4].rule = []; this.form[5].rule = []; this.form[6].rule = [];
            this.form[7].rule = []; this.form[8].rule = []; this.form[11].rule = []; this.form[12].rule = []; this.form[13].rule = []; this.form[14].rule = [];
            this.form[15].rule = []; this.form[16].rule = []; this.form[17].rule = []; this.form[18].rule = []; this.form[19].rule = []; this.form[20].rule = [];
            this.form[21].rule = []; this.form[22].rule = []; this.form[23].rule = [];
            this.form[9].rulestart = []; this.form[9].ruleend = []; this.form[10].rulestart = []; this.form[10].ruleend = [];
        },

        async beforeCreateForm(flag){
            console.log('start', this.form[9].range.start);
            this.onRuleChange();
                
            let qtymax = this.form[6].qty !== null && Number(this.form[8].maxpurchaseqty) >= Number(this.form[6].qty);
            let qtymin = this.form[6].qty !== null && Number(this.form[7].minpurchaseqty) >= Number(this.form[6].qty);
            let qtymin2 = this.form[6].qty !== null && Number(this.form[7].minpurchaseqty) > Number(this.form[8].maxpurchaseqty);
            let multientrycheck = this.form[13].multientry && this.form[14].numofentry === '';
            let durationcheck = this.form[12].selected === 'time' && this.form[15].allowedparkingdurationhours === '';
            let imagecheck = this.form[4].image === '';

            this.form[0].rule = [
                v => !!v || 'Please select Car Park'
            ];

            this.form[1].rule = [
                v => !!v || 'Value Pass Name is required',
            ];

            this.form[2].rule = [
                v => !!v || 'Description is required',
            ];

            this.form[3].rule = [
                v => !!v || 'Terms & Conditions are required',
            ];

            this.form[4].imgrule = [
                v => !imagecheck || 'Image is required',
            ];

            this.form[6].rule = [
                v => !!v || 'Quantity is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[7].rule = [
                v => !!v || 'Minimum Purchase is required',
                v => !qtymin || 'Min quantity should be lesser than quantity',
                v => !qtymin2 || 'Min quantity should be lesser than max quantity',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];
            
            this.form[8].rule = [
                v => !!v || 'Maximum Purchase is required',
                v => !qtymax || 'Max quantity should be lesser than quantity',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[11].rule = [
                v => !!v || 'Price is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed',
                v => Number(v) !== 0 || 'Price should be more than 0'
            ];

            this.form[12].rule = [
                v => !!v || 'Type is required',
            ];

            this.form[15].rule = [
                v => !durationcheck || 'Duration is required',
                v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
            ];

            this.form[9].rulestart = [
                v => !!v || 'Date is required',
            ];

            this.form[10].rulestart = [
                v => !!v || 'Date is required',
            ];

            this.form[9].ruleend = [
                v => !!v || 'Date is required',
            ];

            this.form[10].ruleend = [
                v => !!v || 'Date is required',
            ];

            if (this.form[9].range.end != '' && this.form[9].range.hasOwnProperty('end')){
                this.form[9].rulestart = [
                    v => moment(moment(this.form[9].range.start).format('YYYY-MM-DD')).isBefore(moment(this.form[9].range.end).format('YYYY-MM-DD')) && this.form[9].range.end != '' || 'Date should be before end date'
                ];
            }

            if (this.form[9].range.start != '' && this.form[9].range.hasOwnProperty('start')){
                this.form[9].ruleend = [
                    v => !moment(moment(this.form[9].range.end).format('YYYY-MM-DD')).isBefore(moment(this.form[9].range.start).format('YYYY-MM-DD')) || 'Date should be after start date',
                    v => this.form[9].range.start != '' || 'There should be a start date'
                ];
            }

            if (this.form[10].range.end != '' && this.form[10].range.hasOwnProperty('end')){
                this.form[10].rulestart = [
                    v => moment(moment(this.form[10].range.start).format('YYYY-MM-DD')).isBefore(moment(this.form[10].range.end).format('YYYY-MM-DD')) && this.form[10].range.end != '' || 'Date should be before end date'
                ];
            }

            if (this.form[10].range.start != '' && this.form[10].range.hasOwnProperty('start')){
                this.form[10].ruleend = [
                    v => !moment(moment(this.form[10].range.end).format('YYYY-MM-DD')).isBefore(moment(this.form[10].range.start).format('YYYY-MM-DD')) || 'Date should be after start date',
                    v => this.form[10].range.start != '' || 'There should be a start date'
                ];
            }

            if (this.displayitems.utilizetype.selected === '2' || this.displayitems.utilizetype.selected === '4'){
                this.form[23].rule = [
                    v => Number.isInteger(Number(v)) || 'Only integer is allowed',
                    //v => Number(v) > 0 || 'Please enter a value more than 0'
                ];
            }

            if (this.displayitems.utilizetype.selected === '3' || this.displayitems.utilizetype.selected === '4'){
                this.form[14].rule = [
                    v => Number.isInteger(Number(v)) || 'Only integer is allowed',
                    //v => Number(v) > 0 || 'Please enter a value more than 0'
                ];
            }

            if (this.form[12].selected == "time" && this.displayitems.utilizetype.selected !== ""){
                this.form[16].rule = [
                    v => !!v || 'Time is required',
                ];

                this.form[17].rule = [
                    v => !!v || 'Time is required',
                ];

                this.form[18].rule = [
                    v => !!v || 'Time is required',
                ];

                this.form[19].rule = [
                    v => !!v || 'Time is required',
                ];
            }

            if (this.form[12].selected == "duration" && this.displayitems.utilizetype.selected !== ""){
                this.form[16].rule = [
                    v => !!v || 'Time is required',
                ];

                this.form[17].rule = [
                    v => !!v || 'Time is required',
                ];
            }

            this.form[25].rule = [
                v => Number.isInteger(Number(v)) || 'Only integer is allowed'
            ];

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode != 9)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        async handleImageMain(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);

            if (v){
            this.form[4].btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form[4].image = base64img;
            this.form[4].btn_loading = false;
            let check = await this.getImageDimensions(this.form[4].image, 400, 400);
            if (!check){
                this.form[4].image = '';
                this.form[4].file = '';
                this.form[4].imgrule = [
                    v => false || 'Please ensure image is 400 x 400'
                ];
                console.log(this.form[4].imgrule);
            }

            else{
                this.form[4].imgrule = [];
            }
            }

            else{
                await this.$nextTick(async () => {
                this.form[4].image = this.items[0].Images[0];
                this.form[4].btn_loading = false;
                });
            }
        },

        getImageDimensions(file, w, h) {
            if (file != ''){
                let fact;
                return new Promise (function (resolved, rejected) {
                    var i = new Image()
                    i.onload = function(){
                        if (i.width !== w || i.height !== h){
                            fact = false;
                        }

                        else{
                            console.log('truth', 'true');
                            fact = true;
                        }

                        resolved(fact);
                    };

                    i.src = file;
                });
            }

            else{
                return true;
            }
        },

        getBase64(file) {
            if (file){
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
        },

        addDropFile4(e) { this.form[4].file = e.dataTransfer.files[0]; },

        changeOrder(e) {
            e.preventDefault();
            e.stopPropagation();
        },

        numofentrycheck(v){
            if (v == false){
                this.form[14].numofentry = "";
                this.form[14].rule = [];
            }
        },

        durationcheck(v){
            if (v !== 'time'){
                this.form[15].allowedparkingdurationhours = "";
                this.form[15].rule = [];
            }
        },

        onDayClick(day) {
            const idx = this.form[22].allowedentrydates.findIndex(d => d.id === day.id);
            if (idx >= 0) {
                this.form[22].allowedentrydates.splice(idx, 1);
            } else {
                this.form[22].allowedentrydates.push({
                id: moment(day.id).format('DD/MM/YYYY'),
                date: day.date,
                });
            }
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
            }
        },

        selectUtilizeType(){
            if (this.form[14].numofentry === 1 && this.form[23].numofparkingdays === 1){
                this.displayitems.utilizetype.selected = '1';
            }

            if (this.form[14].numofentry === 1 && (this.form[23].numofparkingdays > 1 || this.form[23].numofparkingdays == null)){
                this.displayitems.utilizetype.selected = '2';
            }

            if ((this.form[14].numofentry > 1 || this.form[14].numofentry == null) && this.form[23].numofparkingdays === 1){
                this.displayitems.utilizetype.selected = '3';
            }

            if ((this.form[14].numofentry > 1 || this.form[14].numofentry == null) && (this.form[23].numofparkingdays > 1 || this.form[23].numofparkingdays == null)){
                this.displayitems.utilizetype.selected = '4';
            }
        },

        isNumOfEntryTrue(){
            let istrue = true;
            if (this.displayitems.utilizetype.selected === '' || this.displayitems.utilizetype.selected === '1' || this.displayitems.utilizetype.selected === '2'){
                istrue = false;
            }

            else{
                istrue = true;
            }

            return istrue;
        },

        isNumOfParkingDaysTrue(){
            let istrue = true;
            if (this.displayitems.utilizetype.selected === '' || this.displayitems.utilizetype.selected === '1' || this.displayitems.utilizetype.selected === '3'){
                istrue = false;
            }

            else{
                istrue = true;
            }

            return istrue;
        },

        removeValStart(){
            this.form[9].range.start = '';
        },

        removeValEnd(){
            this.form[9].range.end = '';
        },

        removeValStartUse(){
            this.form[10].range.start = '';
        },

        removeValEndUse(){
            this.form[22].allowedentrydates = [];
        },

        selectAll(){
            for(let [index, item] of this.form[20].alloweddays.entries()){
                if (this.select_all === true){
                    let ifChecked = this.form[20].selected.some(i => {
                        return i == item.id;
                    })

                    if (!ifChecked)
                        this.form[20].selected.push(item.id);
                }

                else{
                    this.form[20].selected = [];
                }
            }
        },

        dateValStartTime(value){
            this.form[16].entrytimestart = value;
        },

        dateValEndTime(value){
            this.form[17].entrytimeend = value;
        },

        dateValExitStartTime(value){
            this.form[18].exittimestart = value;
        },

        dateValExitEndTime(value){
            this.form[19].exittimeend = value;
        }
    }
}
</script>